// extracted by mini-css-extract-plugin
export var btnDefault = "privacy-module--btn-default--214Mk";
export var attoiconJobs = "privacy-module--attoicon-jobs--d3qxd";
export var attoiconNotes = "privacy-module--attoicon-notes--Ob9H8";
export var attoiconTimesheets = "privacy-module--attoicon-timesheets--kv52v";
export var attoiconTimesheetsRounded1 = "privacy-module--attoicon-timesheets-rounded1--jzK6a";
export var attoiconMobileTt = "privacy-module--attoicon-mobile-tt--ltutW";
export var attoiconNamedSites = "privacy-module--attoicon-named-sites--0OP+3";
export var attoiconProfitableBids = "privacy-module--attoicon-profitable-bids--HvDTl";
export var attoiconDepartments = "privacy-module--attoicon-departments--M4ESf";
export var attoiconTimeline = "privacy-module--attoicon-timeline--psgNg";
export var attoiconFilters = "privacy-module--attoicon-filters--tBfoB";
export var attoiconVerified = "privacy-module--attoicon-verified--Uf2Wo";
export var attoiconTimezone = "privacy-module--attoicon-timezone--6UCJ9";
export var attoiconManualEntries = "privacy-module--attoicon-manual-entries--9foZa";
export var attoiconJobCosting = "privacy-module--attoicon-job-costing--KFEpo";
export var attoiconLocationTracking = "privacy-module--attoicon-location-tracking--uORVr";
export var attoiconBreak = "privacy-module--attoicon-break--PWcug";
export var attoiconNightShift = "privacy-module--attoicon-night-shift--qoamd";
export var attoiconOvertime = "privacy-module--attoicon-overtime--VwCEy";
export var attoiconTimeTracking1 = "privacy-module--attoicon-time-tracking1--MlOWP";
export var attoiconTimeTracking = "privacy-module--attoicon-time-tracking--qkQ4V";
export var attoiconLocationHistory = "privacy-module--attoicon-location-history--Tb8lq";
export var attoiconPrivacy = "privacy-module--attoicon-privacy--1WkX5";
export var attoiconTimeEntryHistory = "privacy-module--attoicon-time-entry-history--X3-Ya";
export var attoiconCustomize = "privacy-module--attoicon-customize--I-S8k";
export var attoiconRoundedClock = "privacy-module--attoicon-rounded-clock--iKL8P";
export var attoiconTimeOff = "privacy-module--attoicon-time-off--tw0qU";
export var attoiconNamedLocations = "privacy-module--attoicon-named-locations--YvzmA";
export var attoiconSandClock = "privacy-module--attoicon-sand-clock--2amEb";
export var attoiconBattery = "privacy-module--attoicon-battery--6wrsq";
export var attoiconSupport = "privacy-module--attoicon-support--uEpyv";
export var attoiconHelpSupport = "privacy-module--attoicon-help-support--Sd168";
export var attoiconWebTt = "privacy-module--attoicon-web-tt--vL6VQ";
export var attoiconArchive = "privacy-module--attoicon-archive--J2STh";
export var attoiconEmail = "privacy-module--attoicon-email--+64GL";
export var attoiconKiosk = "privacy-module--attoicon-kiosk--diLmN";
export var attoiconShare = "privacy-module--attoicon-share--c+x7v";
export var attoiconCrew = "privacy-module--attoicon-crew--8gyCn";
export var attoiconTeamActivity = "privacy-module--attoicon-team-activity--HLvsq";
export var attoiconTeam = "privacy-module--attoicon-team--+cZvc";
export var attoiconWages = "privacy-module--attoicon-wages--wgQVx";
export var attoiconNotification = "privacy-module--attoicon-notification--MVw+Z";
export var attoiconAvatar = "privacy-module--attoicon-avatar--Kdirv";
export var attoiconViewMap = "privacy-module--attoicon-view-map--PdmCW";
export var attoiconMovementTracking = "privacy-module--attoicon-movement-tracking--ENz-0";
export var attoiconWageEstimates = "privacy-module--attoicon-wage-estimates--9SZZV";
export var attoiconWageEstimatesBold = "privacy-module--attoicon-wage-estimates-bold--sFpj3";
export var attoiconClose = "privacy-module--attoicon-close--317-s";
export var attoiconPlus = "privacy-module--attoicon-plus--HOLCE";
export var attoiconMinus = "privacy-module--attoicon-minus--gWVSi";
export var attoiconTick = "privacy-module--attoicon-tick--QK7xw";
export var attoiconArrowLeft = "privacy-module--attoicon-arrow-left--Z1pBX";
export var attoiconArrowRight = "privacy-module--attoicon-arrow-right--UCE4i";
export var attoiconArrowDown = "privacy-module--attoicon-arrow-down--nXJof";
export var attoiconArrowUp = "privacy-module--attoicon-arrow-up--vYLAV";
export var attoiconPlay = "privacy-module--attoicon-play--hsKPq";
export var attoiconLongArrow = "privacy-module--attoicon-long-arrow--BHgd4";
export var privacyPolicyStyle = "privacy-module--privacyPolicyStyle--ULfUP";